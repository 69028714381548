import React, { useState } from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import Layout from "../components/Layout"
import NavWithHeaderAndText from "../components/AcfPageBlocks/NavWithHeaderAndText"
import { PostItemCard } from "../components/UI/Post/PostItemCard"
import * as styles from "./blog.module.scss"

export const query = graphql`
  query blogPageQuery {
    wpPage(id: { eq: "cG9zdDoyNDA=" }) {
      flexLayouts {
        flexibleLayouts {
          ... on WpPage_Flexlayouts_FlexibleLayouts_NavWithHeaderAndText {
            sectionHeader
            headingType
            paragraph
          }
        }
      }
    }
    bkgdImage: file(relativePath: { eq: "bkgd-dots-optimized.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`

const Blog = ({ pageContext, data, location, history }) => {
  const {
    sectionHeader,
    paragraph,
  } = data.wpPage.flexLayouts.flexibleLayouts[0]
  const bkgdImage = getImage(data?.bkgdImage)

  const {itemsPerPage, allPosts, posts, totalElements, page, nextPagePath} = pageContext

  const [postsToShow, setPostsToShow] = useState(posts)
  const [loadMoreEnable, setLoadMoreEnable] = useState(page === 1 && itemsPerPage < totalElements)

  const loadMorePosts = event => {
    event.preventDefault()
    const size = postsToShow.length + itemsPerPage;
    setPostsToShow([...allPosts.slice(0, size)])
    setLoadMoreEnable(page === 1 && size < totalElements)
  }

  return (
    <>
      <Layout
        location={location}
        seo={pageContext.seo}
        paginationComponent={{
          nextLink: pageContext.nextPagePath,
          prevLink: pageContext.prevPagePath,
          currentLink: pageContext.currentPagePath
        }}>
        <main>
          <NavWithHeaderAndText
            sectionHeader={sectionHeader}
            paragraph={paragraph}
            location={location}
            crumbLabel={page === 1 ? "Blog" : `${page}`}
            history={history}
            pageContext={pageContext}
            headingType='h1'
          />
          <div className="bg-pale-grey position-relative h-100 p-0">
            <BgImage image={bkgdImage} style={{ backgroundRepeat: "repeat-y" }}>
              <Container>
                <div className="position-relative h-100 px-0 py-6">
                  <Row>
                    {postsToShow.map(post => {
                      const {
                        title,
                        date,
                        id,
                        slug,
                        postsLayouts: { postObject }
                      } = post

                      const { mainImage } = postObject[0]

                      const mainImg = getImage(mainImage?.localFile)

                      return (
                        <Col
                          className="d-flex justify-content-center justify-content-lg-start mb-4"
                          sm={12}
                          lg={4}
                          key={id}>
                          <PostItemCard
                            slug={slug}
                            image={mainImg}
                            alt={mainImage?.altText}
                            date={date}
                            title={title}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                </div>
                {loadMoreEnable && page ===1 && (
                  <div className="d-flex flex-column justify-content-center align-items-center pt-5 pb-6">
                    <a className={styles.loadMoreBtn} onClick={loadMorePosts} href={nextPagePath}>Load More</a>
                  </div>
                )}
                {page !== 1 && nextPagePath && (
                  <div className="d-flex justify-content-center pt-5 pb-6">
                    <a className={styles.loadMoreBtn} href={nextPagePath}>Load More</a>
                  </div>
                )}
              </Container>
            </BgImage>
          </div>
        </main>
      </Layout>
    </>
  )
}

export default Blog
